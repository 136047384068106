/* <RESET_CSS> */
html {
  box-sizing: border-box;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  border-collapse: collapse;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  font-family: 'ProximaNova', sans-serif;
  border-collapse: collapse;
}

body {
  font-family: 'ProximaNova', sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

h1,
h3,
h4,
h5,
h6 {
  font-family: 'ProximaNova', sans-serif;
  font-weight: 600;
}

i, b, strong, em{
  display: inline-block !important;
}

h2{
    font-family: 'PTSerif', sans-serif;
    font-weight: 400;
}


input,
button,
textarea,
select {
  font: inherit;
  color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a:focus {
  outline: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border: var(--secondary-color) 1px solid;
  width: 100%;
}

th,
td {
  border: var(--secondary-color) 1px solid;
  color: var(--primary-color);
  text-align: left;
  padding: 0.5rem;
}

#root,
#__next {
  isolation: isolate;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  padding: 0;
  line-height: 1.15;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

@media (max-width: 768px) {
  html {
    font-size: 87.5%;
  }

  a{
    font-size: 87.5%;
  }
}

/* </RESET_CSS> */


/* CUSTOM_CSS */
div.formatted-content{
  font-family: 'PTSerif', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  padding: 0;
  hyphens: auto;
  margin: 1rem 0;
}

div.formatted-content p{
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}

div.formatted-content strong u{
  font-family: 'PTSerif', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #000;
  text-decoration: none;
}

div.formatted-content ul{
  list-style-type: circle;
  padding: 0;
  margin: 0  0 15px 0;
}

div.formatted-content ul>li{
  margin: 0 0 0 18px;
  padding: 0;
}

div.formatted-content a{
  color: var(--primary-color-orange);
  text-decoration: none;
}

div.formatted-content span{
  display: inline-block;
}

div.formatted-content>div>p:has(>em){
  background: #00a4991a;
  padding: 15px;
  margin: 5px 0;
  border-radius: 5px;
}


