:root {
    --primary-color: #006269;
    --primary-color-orange: #f5875f;
    --primary-color-opaque: rgba(0, 98, 105, 0.1);
    --primary-color-dimmed: rgba(0, 98, 105, 0.15);
    --primary-color-lightgreen: #40c1ac;
    --primary-color-green: #5bb872;
    --primary-color-green-light: rgba(245, 135, 95, 0.1);
    --primary-color-green: #007377;
  
    --secondary-color: #00b0b9;
    --secondary-color-lightblue: #88dbdf;
    --secondary-color-lighterblue: #d9e5e9;
    --secondary-color-lightblue-hover: #c3d4dd;
    --secondary-dark-blue: #004f71;
    --color-silver: #eaeaea;
    --color-silver-dark: #c1c1c1;
    --color-white: #fff;
    --color-yellow: #f8f6f2;
    --color-green: #5bb872;
    --color-lightorange: #fef3ef;
  
    --color-black: #2f2f2f;
    --color-black-opacity-70: #2f2f2fb2;
    --color-black-opacity-50: #2f2f2f80;
    --color-dark-grey: #868686;
    --color-lighter-grey: #979797;
    --color-grey: #dcdcdc;
    --color-light-grey: #f8f6f3;
    --color-dark-beige: #f2ede7;
    --color-grey-darker: #979797;
    --color-feedback-success: #57a773;
    --color-feedback-warning: #f19953;
    --color-feedback-error: #a40000;
    --color-validation-success: #94cd98;
    --color-validation-error: #d86464;
    --color-validation-error-light: rgba(216, 100, 100, 0.3);
  
    --color-cararra-light: #f3f2f0;
    --color-cararra: #edebe8;
    --color-cararra-dark: #f5f4f2;
  
    --color-alto: #d9d9d9;
  
    --footer-background-color: #00555b;
    --header-background-color: #00555b;
  
    --color-gallery: #f0f0f0;
    --color-alabaster: #fafafa;
    --color-mystic: #d9e8e9;
    --color-elm: #168386;
    --color-pearl-bush-75: #eee8dfc4;
    --color-pearl-bush: #eee8df;
    --color-cornflower-blue: #b3ad9d30;
    --color-satin-linen: #e3ded5;
    --color-harp: #dbebe6;
    --color-nomad: #b3ad9dbf;
    --color-jetstream: #c0dad6;
    --shadow-green: #9fc6c4;
  
    --color-footer-link-hover: rgba(245, 135, 95, 0.2);
  
    --color-clients-stories-tiles-border: #d7d7d7;
    --color-client-stories-author-border: #d8d8d8;
    --color-client-stories-load-button: #f5875f;
    --color-client-stories-slider-popup-grey: #979797;
    --color-clients-stories-background: rgba(0, 98, 105, 0.1);
    --color-client-stories-tiles-popup: rgba(0, 0, 0, 0.6);
  
    --color-blog-featured-slider-button-opacity: rgba(0, 98, 105, 0.15);
  
  
    --font-size: 1.8rem;
  
    --font-ptserif: "PTSerif", sans-serif;
    --font-dancingscript: "DancingScript", sans-serif;
    --font-proximanova: "ProximaNova", sans-serif;
    --font-canela: "Canela Web", sans-serif;
    --font-canela-thin: "Canela Thin", sans-serif;
  
    --font-weight: 400;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
  
    --content-wrapper-width: 1440px;
    --content-width-desktop: 1048px;
    --logo-width-desktop: 140px;
    --logo-width-mobile: 100px;
  
  
    --content-padding: 15px;
    --mobile-header-bottom-padding: 55px;
    --blog-cta-side-padding: 4rem;
  
  
    --cta-button-width-mobile: 80vw;
  }

  .active-header-link {
    color: #5bb872 !important;
    font-weight: bold;
}

  